@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    /* //ALLARIA + COLOR SYSTEM */

    /* //PRIMARY SCALE */
    --primary-light-50: #e6eef8;
    --primary-light-100: #d9e5f4;
    --primary-light-200: #b0c9e9;
    --primary-normal-300: #0150b7;
    --primary-normal-400: #0148a5;
    --primary-normal-500: #014092;
    --primary-dark-600: #013c89;
    --primary-dark-700: #01306e;
    --primary-dark-800: #002452;
    --primary-dark-900: #001c40;

    /* //SECONDARY SCALE */
    --secondary-light-50: #ecedf0;
    --secondary-light-100: #d1d5db;
    --secondary-light-200: #acb3bf;
    --secondary-normal-300: #8690a1;
    --secondary-normal-400: #626f84;
    --secondary-normal-500: #3f4f69;
    --secondary-dark-600: #364359;
    --secondary-dark-700: #2d384b;
    --secondary-dark-800: #242d3c;
    --secondary-dark-900: #1c242f;

    /* //ACCENT SCALE */
    --accent-light-50: #f9f6ed;
    --accent-light-100: #f6f2e4;
    --accent-light-200: #ede3c8;
    --accent-normal-300: #c6a64e;
    --accent-normal-400: #b29546;
    --accent-normal-500: #9e853e;
    --accent-dark-600: #957d3b;
    --accent-dark-700: #77642f;
    --accent-dark-800: #594b23;
    --accent-dark-900: #453a1b;

    /* //GREY SCALE */
    --white: #ffffff;
    --greyscale-50: #fcfcfc;
    --greyscale-100: #f5f5f5;
    --greyscale-200: #f0f0f0;
    --greyscale-300: #d9d9d9;
    --greyscale-400: #bfbfbf;
    --greyscale-500: #8c8c8c;
    --greyscale-600: #595959;
    --greyscale-700: #454545;
    --greyscale-800: #262626;
    --greyscale-900: #1f1f1f;
    --greyscale-950: #141414;
    --black: #000000;

    /* //ERROR SCALE */
    --e50: #f5e8ed;
    --e75: #d7a1b4;
    --e100: #c77a95;
    --e200: #ae4068;
    --e300: #9e1949;
    --e400: #6f1233;
    --e500: #600f2d;

    /* //WARNING SCALE */
    --w50: #fcf6e8;
    --w75: #f1dc9f;
    --w100: #eccd78;
    --w200: #e4b83e;
    --w300: #dea916;
    --w400: #9b760f;
    --w500: #87670d;

    /* //SUCCESS SCALE */
    --s50: #e8f5ed;
    --s75: #a1d7b6;
    --s100: #7ac798;
    --s200: #40ae6c;
    --s300: #199e4e;
    --s400: #126f37;
    --s500: #0f6030;

    /* //INFO SCALE */
    --i50: #e9f3f9;
    --i75: #a3cce5;
    --i100: #7db7da;
    --i200: #4497cb;
    --i300: #1e82c0;
    --i400: #155b86;
    --i500: #124f75;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p,
a,
button {
    font-family: 'Poppins', sans-serif;
}

h2 {
    font-size: 1.25rem;
    font-weight: 500;
}

h1 {
    font-size: 1rem;
    font-weight: 400;
}

h3 {
    font-weight: 500;
    font-size: 0.8rem;
    border-top: 0.06rem solid var(--primary-light-100);
    color: var(--primary-normal-400);
    padding: 0.4rem 0;
}

.logo {
    height: auto;
    width: 30%;
}

.wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 3rem;
    background: var(--white);
    padding: 0 2rem
}

.card {
    display: flex;
    flex-direction: column;
    flex-basis: 10.2rem;
    max-width: 10.2rem;
    background: var(--white);
    border: 0.0625rem solid var(--primary-light-100);
    border-radius: 0.25rem;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    -webkit-tap-highlight-color: transparent;
}

.card svg {
    margin: 1.875rem 1.5625rem 1.5625rem 1.5625rem
}

.card .blur {
    opacity: 0.25;
}

.initial {
    opacity: 1;
}

.selected {
    box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
}

.notSelected {
    opacity: 0.25;
}

.cardWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.2rem 0;
    position: relative;
    width: 25rem;
}

.tooltip {
    background-color: #FFFFFF;
    position: absolute;
    border: 1px solid var(--primary-light-100);
    border-radius: 4px;
    font-weight: 400;
    font-size: 11px;
    color: #A6A6BE;
    max-width: 243px;
    padding: 0.75rem;
    box-shadow: 0px 16px 40px rgb(112 144 176 / 20%);
}

.tooltip h4 {
    font-size: .75rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.tooltip p {
    font-size: .70rem;
    font-weight: 400;
}

.tooltip :after {
    content: "";
    position: absolute;
    margin-left: -9px;
    width: 14px;
    height: 14px;
    background: #ffffff;
    transform: rotate(45deg);
}

.tooltipLeft {
    left: -3px;
}

.tooltipLeft :after {
    top: 0.5rem;
    left: 101%;
    border-top: 1px solid var(--primary-light-100);
    border-right: 1px solid var(--primary-light-100);
}

.tooltipRight {
    left: 160px;
}

.tooltipRight :after {
    top: 0.5rem;
    left: 0.5%;
    border-bottom: 1px solid var(--primary-light-100);
    border-left: 1px solid var(--primary-light-100);
}

.hide {
    display: none !important;
}

.footerText {
    font-weight: 400;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 2rem;
}

.footerText p {
    color: #000046;
}

.footerText a {
    color: var(--primary-normal-400);
    font-weight: 500;
    text-decoration: none;
    margin-left: .25rem;
}

.footerText a:hover {
    color: var(--primary-normal-500);
}

.accountTypeInfo {
    display: none;
    font-size: 11px;
    color: #000046;
    margin-bottom: 2rem;
}

.button {
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    height: 2rem;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    line-height: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: var(--primary-normal-300);
    border: 1px solid var(--primary-normal-300);
    color: #fff;
    width: 100%;
}

.button:focus {
    outline: none !important;
}


.button:hover:not([disabled]) {
    background-color: var(--primary-normal-500) !important;
    border-color: var(--primary-normal-500) !important;
}

.button:disabled {
    background-color: #A6A6BE !important;
    border-color: #A6A6BE;
    cursor: default;
}



@media (max-width: 768px) {
    .logo {
        display: none;
    }

    .wrapper {
        align-items: flex-start;
        padding-top: 2rem;
        text-align: center;
    }

    .tooltip {
        display: none;
    }

    .leftColumn {
        flex-basis: 25rem;
    }

    .cardWrapper {
        width: auto;
    }

    .accountTypeInfo {
        display: block;
    }
}

@media (max-width: 430px) {
    .leftColumn:first-of-type p {
        font-size: 13px;
    }

    .card {
        flex-basis: 123px;
    }

    .card svg {
        width: 53.55px;
        height: 53.55px;
        margin: 22px 20px 20px;
    }

}